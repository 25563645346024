/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W 2021 roku toczyły się prace związane z przebudową drogi gminnej nr 150556C w miejscowości Sikorowo (województwo kujawsko-pomorskie)."), "\n", React.createElement(_components.p, null, "W ramach inwestycji zostały wykonane między innymi:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "nowa, nawierzchnia drogi (o szerokości 5 m),"), "\n", React.createElement(_components.li, null, "przebudowa drogi wewnętrznej (zlokalizowana na działkach nr 260/3 i 292) w Sikorowie,"), "\n"), "\n", React.createElement(_components.p, null, "Przebudowany odcinek drogi prowadzi od skrzyżowania z drogą gminną administrowaną przez Miasto Inowrocław (ul. Pokojowa), do skrzyżowania z drogą wewnętrzną, a dalej do drogi serwisowej Obwodnicy Miasta Inowrocławia, stanowiącą drogę krajową nr 15."), "\n", React.createElement(_components.p, null, "Droga została oddana do użytku w listopadzie 2021 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
